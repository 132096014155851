import React, { SVGProps } from "react";

export interface SessionsIconProps extends SVGProps<SVGSVGElement> {}

export const SessionsIcon: React.FC<SessionsIconProps> = (props) => {
  return (
    <svg
      {...props}
      width="120"
      viewBox="0 0 120 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="25.7071"
        y="23.4578"
        width="71.1202"
        height="75.349"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.03027 23.2966C3.03027 10.5773 13.3413 0.266296 26.0606 0.266296H93.9394C106.659 0.266296 116.97 10.5773 116.97 23.2966V97.236C116.97 109.955 106.659 120.266 93.9394 120.266H26.0606C13.3413 120.266 3.03027 109.955 3.03027 97.236V23.2966ZM82.171 88.9649C88.2695 84.803 91.3102 79.3161 91.3102 72.5211V72.5041C91.3102 67.8496 89.9852 63.9765 87.3352 60.8678C84.6682 57.7591 80.5572 55.4998 75.0193 54.0388C72.5222 53.4273 70.025 52.9007 67.5449 52.493C65.0647 52.0853 62.7544 51.6606 60.597 51.2359C56.6899 50.4205 54.2098 49.5881 53.1565 48.7727C52.1033 47.9574 51.5597 46.7682 51.5597 45.2054C51.5597 43.4727 52.7319 42.1477 55.0591 41.2134C57.3864 40.279 60.2403 39.8034 63.6548 39.8034C67.7487 39.8034 71.8087 40.4829 75.8177 41.8249C79.8268 43.1669 83.3941 44.9166 86.5198 47.057H88.3374V30.4094C85.7893 29.2712 82.2729 28.218 77.7543 27.2157C73.2526 26.2305 68.5132 25.7208 63.6038 25.7208C53.2075 25.7208 45.0026 27.8273 39.006 32.0232C33.0095 36.2191 30.0027 41.4852 30.0027 47.8215C30.0027 52.7478 31.4466 56.8588 34.3345 60.1543C37.2224 63.4669 41.6561 65.896 47.6356 67.4589C49.827 68.0535 52.2562 68.5461 54.9402 68.9538C57.6242 69.3615 59.7477 69.7352 61.3105 70.0919C64.5891 70.7545 66.8144 71.4339 67.9696 72.1644C69.1417 72.8949 69.7362 74.067 69.7362 75.6468C69.7362 77.4475 68.734 78.8065 66.7465 79.7238C64.7589 80.6411 61.6842 81.0998 57.5563 81.0998C54.3966 81.0998 51.4238 80.777 48.6379 80.1315C45.8689 79.503 43.3888 78.7215 41.1974 77.8212C38.6493 76.8189 36.6957 75.8677 35.3028 74.9843C33.9098 74.101 32.6867 73.2856 31.6335 72.5551H29.6969V89.9162C32.6018 91.2582 36.4409 92.4643 41.1974 93.5515C45.9539 94.6557 51.2709 95.1993 57.1316 95.1993C67.7317 95.1993 76.0725 93.1269 82.171 88.9649Z"
        fill="#2563EB"
      />
    </svg>
  );
};
