import React from "react";
import styles from "./MessageItem.module.css";
import { Galleria } from "primereact/galleria";
import { Typography } from "../typography/Typography";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Divider } from "primereact/divider";

const Icon = (props: { isUser: boolean }) => (
  <i
    className={`pi ${props.isUser ? "pi-user" : "pi-android"}`}
    style={{
      fontSize: "1.5rem",
      marginRight: "10px",
      color: "var(--text-body)",
    }}
  />
);

const Typing = () => (
  <div className={styles.typing}>
    <div className={styles.dots}>
      <div style={{ opacity: 0.0 }}>I</div>
      <div className={styles.typing__dot}></div>
      <div className={styles.typing__dot}></div>
      <div className={styles.typing__dot}></div>
    </div>
  </div>
);

type ItemProps = React.PropsWithChildren<{ isUser: boolean }>;

const Item: React.FC<ItemProps> = ({ isUser, children }) => (
  <div
    style={{
      display: "flex",
      alignItems: "center", // Add this to align items vertically
      marginBottom: "10px",
    }}
  >
    <Icon isUser={isUser} />
    <div
      className="p-component"
      style={{
        flex: 1,
        paddingLeft: "10px",
        paddingRight: "10px",
        background: isUser ? "#f0f0ff" : "#f0f0f0",
        padding: "15px",
        borderRadius: "10px",
      }}
    >
      {children}
    </div>
  </div>
);

export const MessageItem: React.FC<ReadableMessage> = ({
  content,
  type,
  videoReferences,
}) => {
  const responsiveOptions = [
    {
      breakpoint: "991px",
      numVisible: 4,
    },
    {
      breakpoint: "767px",
      numVisible: 3,
    },
    {
      breakpoint: "575px",
      numVisible: 1,
    },
  ];

  const itemTemplate = ({
    url,
    from,
    to,
    title,
  }: Required<ReadableMessage>["videoReferences"][number]) => {
    /* this uses https://caniuse.com/media-fragments for timing */
    const src = `${url}#t=${from / 1000 /* to seconds */},${
      to / 1000 /* to seconds */
    }`;

    return (
      <div style={{ aspectRatio: 16 / 9, width: "100%" }}>
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            backgroundColor: `rgba(0,0,0,0.5)`,
          }}
        >
          <Typography
            variant="titleMedium"
            style={{ color: "var(--white-100)", pointerEvents: "none" }}
          >
            {title}
          </Typography>
        </div>
        <video controls className={styles.videoItem} src={src} />
      </div>
    );
  };

  const thumbnailTemplate = ({
    thumbnailUrl,
    title,
  }: Required<ReadableMessage>["videoReferences"][number]) => {
    return (
      <div
        style={{
          width: "64px",
          height: "64px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <img src={thumbnailUrl} width="64px" height="64px" />
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: `rgba(0,0,0,0.5)`,
          }}
        >
          <Typography
            variant="subtitleMedium"
            style={{
              color: "var(--white-100)",
              pointerEvents: "none",
              wordBreak: "break-all",
            }}
          >
            {title.substring(0, 9)}
            {title.length > 9 ? "..." : ""}
          </Typography>
        </div>
      </div>
    );
  };

  return (
    <Item isUser={type === "HUMAN"}>
      <Typography
        style={{ color: "var(--surface-level-0)" }}
        variant="bodyMedium"
      >
        {content}
      </Typography>
      {videoReferences && videoReferences.length > 0 && (
        <>
          <Divider />
          <Accordion>
            <AccordionTab header="Video References">
              <Galleria
                value={videoReferences}
                circular
                showItemNavigators={true}
                numVisible={5}
                style={{ maxWidth: "640px" }}
                item={itemTemplate}
                thumbnail={thumbnailTemplate}
                responsiveOptions={responsiveOptions}
                pt={{
                  nextItemButton: {
                    style: {
                      zIndex: "100",
                    },
                  },
                  previousItemButton: {
                    style: {
                      zIndex: "100",
                    },
                  },
                }}
              />
            </AccordionTab>
          </Accordion>
        </>
      )}
    </Item>
  );
};

export type MessageArray = (PendingMessage | ReadableMessage)[];

export type MessageType = "HUMAN" | "ASSISTANT" | "SYSTEM";

export type PendingMessage = {
  uid: string;
  timestamp: number;
  type: MessageType;
  placeholder: "...";
};

export type ReadableMessage = {
  uid: string;
  content: string;
  timestamp: number;
  type: MessageType;

  /**
   * Messages may contain video references that will be displayed as a gallery playlist below the message when rendered.
   */
  videoReferences?: Array<{
    from: number;
    to: number;
    url: string;
    title: string;
    thumbnailUrl: string;
  }>;
};

export function isPendingMessage(
  m: PendingMessage | ReadableMessage,
): m is PendingMessage {
  return (m as PendingMessage).placeholder !== undefined;
}

type PendingMessageItemProps = PendingMessage;

export const PendingMessageItem: React.FC<PendingMessageItemProps> = ({
  type: source,
}) => (
  <Item isUser={source === "HUMAN"}>
    <Typing />
  </Item>
);
