import React from "react";
import styles from "./ActivityItem.module.css";
import { CompleteIcon, PageLessonIcon, VideoIcon } from "./icons";
import { ActivityType } from "./types";
import { Typography } from "@arena-active/client-lib";
import { IconState } from "./icons/SideNavIconWrapper";
import { ResponseType } from "../layout/types";
import { WriteIcon } from "./icons/WriteIcon";
import { RecordVideoIcon } from "./icons/RecordVideoIcon";
import { RecordAudioIcon } from "./icons/RecordAudioIcon";
import { UploadIcon } from "./icons/UploadIcon";

interface ActivityItemProps {
  lessonPosition: number;
  title?: string;
  label?: string;
  id: number;
  activityType: ActivityType;
  responseType: ResponseType;
  isShowLabel: boolean;
  isShowTitle: boolean;
  showIcon: boolean;
  isCurrent: boolean;
  isComplete: boolean;
  isFirstInSection: boolean;
  isLastInSection: boolean;
  isSectionHasTitle: boolean;
  isShowPosition: boolean;
  theme?: string;
  onClick: (itemId: number, isComplete: boolean) => void;
}

export const ActivityItem: React.FC<ActivityItemProps> = ({
  id,
  lessonPosition,
  title,
  label,
  activityType,
  responseType,
  isShowLabel,
  isShowTitle,
  showIcon,
  isCurrent,
  isComplete,
  isFirstInSection,
  isLastInSection,
  isSectionHasTitle,
  isShowPosition,
  onClick,
}) => {
  const textColorClass = isCurrent
    ? styles.textCurrent
    : isComplete
    ? styles.textComplete
    : styles.textDefault;

  const topBorderClass =
    isFirstInSection && !isSectionHasTitle ? styles.topRounded : "";
  const bottomBorderClass = isLastInSection
    ? styles.bottomRounded
    : styles.flatBottom;

  const activityItemStyle = `${styles.ActivityItem}
  } ${topBorderClass} ${bottomBorderClass} ${
    isComplete ? styles.showCursor : ""
  }`;

  const getLabel = () => {
    if (label) return label;
    if (activityType === ActivityType.EXERCISE) {
      return "Exercise";
    } else {
      switch (activityType) {
        case ActivityType.LESSON_PAGE:
          return "Read";
        case ActivityType.VIDEO:
          return "Watch";
        case ActivityType.UPLOAD:
          return "Upload";
        default:
          return "Lesson";
      }
    }
  };

  const renderIcon = () => {
    if (isComplete) {
      return <CompleteIcon />;
    } else {
      switch (activityType) {
        case ActivityType.LESSON_PAGE:
          return isCurrent ? (
            <PageLessonIcon state={IconState.Current} />
          ) : (
            <PageLessonIcon state={IconState.Inactive} />
          );
        case ActivityType.VIDEO:
          return isCurrent ? (
            <VideoIcon state={IconState.Current} />
          ) : (
            <VideoIcon state={IconState.Inactive} />
          );
        case ActivityType.UPLOAD:
          return isCurrent ? (
            <UploadIcon state={IconState.Current} />
          ) : (
            <UploadIcon state={IconState.Inactive} />
          );
        case ActivityType.EXERCISE:
          switch (responseType) {
            case ResponseType.Text:
              return isCurrent ? (
                <WriteIcon state={IconState.Current} />
              ) : (
                <WriteIcon state={IconState.Inactive} />
              );
            case ResponseType.Video:
              return isCurrent ? (
                <RecordVideoIcon state={IconState.Current} />
              ) : (
                <RecordVideoIcon state={IconState.Inactive} />
              );
            case ResponseType.Audio:
              return isCurrent ? (
                <RecordAudioIcon state={IconState.Current} />
              ) : (
                <RecordAudioIcon state={IconState.Inactive} />
              );
            case ResponseType.File:
              return isCurrent ? (
                <UploadIcon state={IconState.Current} />
              ) : (
                <UploadIcon state={IconState.Inactive} />
              );
            default:
              return (
                <i className="pi pi-question-circle" style={{ width: 24 }}></i>
              );
          }
        default:
          return null;
      }
    }
  };

  return (
    <div className={activityItemStyle} onClick={() => onClick(id, isComplete)}>
      {isShowPosition && (
        <Typography variant={"bodyMediumStrong"}>
          <span
            className={`${styles.textElement} ${styles.position} ${textColorClass}`}
          >
            {lessonPosition}.
          </span>
        </Typography>
      )}
      {isShowLabel && (
        <Typography variant={"bodyMediumStrong"}>
          <span
            className={`${styles.textElement} ${styles.label}  ${textColorClass}`}
          >
            {getLabel()}
          </span>
        </Typography>
      )}
      {isShowTitle && title && (
        <Typography variant={"bodyMedium"} className={styles.titleHolder}>
          <span
            className={`${styles.textElement} ${textColorClass} ${styles.title}`}
          >
            {title}
          </span>
        </Typography>
      )}
      {showIcon && <span className={styles.icon}>{renderIcon()}</span>}
    </div>
  );
};
