import { SideNav } from "../nav/SideNav";
import { NavHeader } from "../nav/NavHeader";
import { ContinueFooter } from "../nav/ContinueFooter";
import { GridLayout } from "../layout/GridLayout";
import { useLessonData } from "#lib/useLessonData";
import { ActivityViewer } from "#lib/lesson/ActivityViewer";
import styles from "./LessonViewer.module.css";
import { classNames } from "primereact/utils";
import { ActivityType } from "../layout/types";
import { useState, useRef, useEffect } from "react";
import { Toast } from "primereact/toast";
import {
  ToastErrorNotification,
  ToastErrorNotificationStyles,
} from "@arena-active/client-lib";
interface LessonViewerProps {
  lessonId: number;
  studentId: number;
}

export const LessonViewer: React.FC<LessonViewerProps> = ({
  lessonId,
  studentId,
}) => {
  const generalErrorToast = useRef<Toast>(null);

  const [selectedActivityId, setSelectedActivityId] = useState<number | null>(
    null,
  );
  const {
    lesson,
    activeSessionState,
    onComplete,
    isLoading,
    error,
    showFooter,
    currentActivity,
  } = useLessonData(studentId, lessonId, selectedActivityId);

  useEffect(() => {
    if (error) {
      generalErrorToast.current?.show({
        sticky: true,
        content: <ToastErrorNotification />,
      });
    }
  }, [error]);

  if (!lesson || !activeSessionState || isLoading || !onComplete) {
    return <div>Loading...</div>;
  }

  const containerClasses: Record<ActivityType, string> = {
    [ActivityType.LESSON_PAGE]: styles.iframeLesson,
    [ActivityType.EXERCISE]: "",
    [ActivityType.VIDEO]: styles.video,
    [ActivityType.UPLOAD]: styles.uploadFiles,
    [ActivityType.CHAT]: "",
  };

  return (
    <>
      <Toast
        ref={generalErrorToast}
        className={ToastErrorNotificationStyles.toastContainer}
        position="bottom-right"
      />
      <GridLayout
        className={classNames(
          styles.container,
          containerClasses[
            (currentActivity?.activityType as ActivityType) ||
              ActivityType.LESSON_PAGE
          ],
        )}
        headerContent={
          <NavHeader
            title={currentActivity?.title ?? ""}
            showResumeButton={!!selectedActivityId}
            onResumeActivity={() => setSelectedActivityId(null)}
          />
        }
        navContent={
          <SideNav
            lesson={lesson}
            activeSessionState={activeSessionState}
            selectedActivityId={selectedActivityId}
            onSelectActivity={(itemId: number) => setSelectedActivityId(itemId)}
          />
        }
        mainContent={
          <ActivityViewer activity={currentActivity} onComplete={onComplete} />
        }
        footerContent={
          showFooter && (
            <ContinueFooter onComplete={onComplete} isLoading={isLoading} />
          )
        }
      />
    </>
  );
};
