import {
  createGUID,
  UserMediaRecorder,
  StorageClient,
  UploadBlob,
  getExtensionForMimeType,
} from "@arena-active/client-lib";
import { Button } from "@arena-active/client-lib";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { TranscriptionService } from "#lib/TranscriptionService";
import { API_URL } from "../config";
import { SubmitPayload } from "./Exercise";
import classNames from "classnames";
import { InputTextarea } from "primereact/inputtextarea";
import { ProgressSpinner } from "primereact/progressspinner";
import { Feedback } from "../lesson/ActivityViewer";
import styles from "./WrittenResponse.module.css";
import type {
  UserMediaRecorderProps,
  UserMediaRecorderAPI,
} from "@arena-active/client-lib";
import { useMediaQuery } from "react-responsive";
import { ErrorIcon } from "./Icons";

interface RecordAgainButtonProps {
  isMobile: boolean | null;
  onClick: () => void;
}

interface SavedMediaBlobs {
  audioBlob: UploadBlob;
  videoBlob: UploadBlob;
}

const RecordAgainButton = ({ isMobile, onClick }: RecordAgainButtonProps) => (
  <Button
    {...(!isMobile && { label: "Record Again" })}
    className={styles.recordAgainButton}
    type="SECONDARY_OUTLINED"
    icon="pi pi-refresh"
    onClick={onClick}
  />
);

export const VideoResponse: FC<{
  feedback?: Feedback;
  storageClient?: ReturnType<typeof StorageClient>;
  initialMediaConstraints?: UserMediaRecorderProps["initialConstraints"];
  onSubmit: (payload: SubmitPayload) => void;
  onComplete: () => void;
  onSwitchToText: () => void;
}> = ({
  feedback,
  initialMediaConstraints,
  storageClient,
  onSubmit,
  onSwitchToText,
}) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [audioResponseObjectKey, setAudioResponseObjectKey] = useState<
    string | undefined
  >(undefined);
  const [videoResponseObjectKey, setVideoResponseObjectKey] = useState<
    string | undefined
  >(undefined);
  const [submitting, setSubmitting] = useState(false);
  const [transcription, setTranscription] = useState<string | undefined>(
    undefined,
  );
  const [uploadError, setUploadError] = useState<boolean | null>(null);
  const [uploading, setUploading] = useState<boolean | null>(null);
  const savedMediaBlobs = useRef<SavedMediaBlobs | null>(null);

  const [transcriptionProcessing, setTranscriptionProcessing] =
    useState<boolean>(false);
  const [transcribeError, setTranscribeError] = useState<Error | null>(null);
  const [editing, setEditing] = useState<boolean>(false);
  const [editedTranscript, setEditedTranscript] = useState<string | undefined>(
    undefined,
  );
  const userMediaRef = useRef<UserMediaRecorderAPI>(null);

  const transcribeAudio = useCallback(() => {
    if (audioResponseObjectKey) {
      const service = new TranscriptionService(API_URL);
      setTranscriptionProcessing(true);
      service
        .transcribe(audioResponseObjectKey)
        .then((result) => {
          setTranscription(result);
          setTranscriptionProcessing(false);

          userMediaRef.current?.setTranscriptionText(result);
        })
        .catch((e) => {
          setTranscribeError(e);
        });
    }
  }, [audioResponseObjectKey]);

  useEffect(() => {
    transcribeAudio();
  }, [audioResponseObjectKey, transcribeAudio]);

  const onRecordingComplete = useCallback(
    async function onRecordingComplete(
      audioBlob: UploadBlob,
      videoBlob: UploadBlob,
    ) {
      console.log("rec compl", audioBlob, videoBlob);

      savedMediaBlobs.current = { audioBlob, videoBlob };

      // TODO: get this from somewhere
      const exerciseId = 1;

      const audioFileBaseName =
        "response/" + createGUID() + exerciseId + "-audio";

      const videoFileBaseName =
        "response/" + createGUID() + exerciseId + "-video";

      setUploading(true);

      Promise.all([
        audioBlob &&
          storageClient
            ?.uploadFile(
              audioBlob,
              `${audioFileBaseName}.${getExtensionForMimeType(audioBlob.type)}`,
              (progress) => {
                console.log(`audio progress: ${progress}`);
              },
            )
            .then((response) => {
              if (response.success) {
                setAudioResponseObjectKey(
                  `${audioFileBaseName}.${getExtensionForMimeType(
                    audioBlob.type,
                  )}`,
                );
                return response;
              }
              throw new Error("audio upload failed");
            }),
        videoBlob?.size > 0 &&
          storageClient
            ?.uploadFile(
              videoBlob,
              `${videoFileBaseName}.${getExtensionForMimeType(videoBlob.type)}`,
              (progress) => {
                console.log(`video progress: ${progress}`);
              },
            )
            .then((response) => {
              if (response.success) {
                setVideoResponseObjectKey(
                  `${videoFileBaseName}.${getExtensionForMimeType(
                    videoBlob.type,
                  )}`,
                );
                return response;
              }
              throw new Error("video upload failed");
            }),
      ])
        .then(() => {
          setUploading(false);
        })
        .catch((e) => {
          console.log("e:", e);
          setUploading(false);
          setUploadError(true);
        });
    },
    [storageClient],
  );

  console.log(
    transcription,
    transcriptionProcessing,
    transcribeError,
    videoResponseObjectKey,
    editing,
  );

  const SubmitRecordAgainContent = !(uploadError || uploading) &&
    !editing &&
    transcription && (
      <div className={styles.buttons}>
        {!feedback && (
          <div>
            {!submitting && (
              <Button
                label="Continue"
                className={styles.button}
                onClick={() => {
                  setSubmitting(true);
                  onSubmit({
                    responseText: transcription,
                    audioStorageKey: audioResponseObjectKey,
                    videoStorageKey: videoResponseObjectKey,
                  });
                }}
              />
            )}
            {!submitting && (
              <RecordAgainButton
                isMobile={isMobile}
                onClick={() => {
                  userMediaRef?.current?.reset();
                  setTranscription("");
                }}
              />
            )}
          </div>
        )}
      </div>
    );

  return (
    <>
      <div className={classNames(styles.record, editing && styles.editing)}>
        {!(uploadError || uploading) && editing && (
          <div className={styles.editTranscriptContainer}>
            <h3>Edit Transcript</h3>
            <InputTextarea
              className={styles.input}
              autoResize={true}
              autoFocus
              rows={1}
              value={editedTranscript}
              placeholder="Write something..."
              onChange={(ev) => setEditedTranscript(ev.target.value)}
            />
            <div className={styles.buttons}>
              <Button
                className={styles.button}
                label="Save"
                onClick={() => {
                  setEditing(false);
                  setTranscription(editedTranscript);
                  userMediaRef.current?.setTranscriptionText(editedTranscript);
                }}
              />
              <Button
                label="Discard"
                type="SECONDARY_OUTLINED"
                onClick={() => setEditing(false)}
              />
            </div>
          </div>
        )}

        {uploading && (
          <div className={styles.uploadingRecording}>
            <div className={styles.uploadContainer}>
              <ProgressSpinner
                style={{
                  background: "transparent",
                  width: "32px",
                  height: "32px",
                }}
                strokeWidth="5"
                fill="var(--surface-level-1)"
                animationDuration=".5s"
              />
              <div className={styles.uploadText}>Uploading Recording</div>
            </div>
            <div className={styles.resetButtons}>
              <RecordAgainButton
                isMobile={isMobile}
                onClick={() => {
                  userMediaRef?.current?.reset();
                  savedMediaBlobs.current = null;
                  setUploading(null);
                  setTranscription("");
                }}
              />
            </div>
          </div>
        )}

        {!uploading && uploadError && (
          <div className={styles.uploadingRecording}>
            <div className={styles.uploadErrorContainer}>
              <ErrorIcon />
              <div>
                <div className={styles.uploadErrorTitle}>
                  Oops! Something Went Wrong
                </div>
                <div className={styles.uploadErrorDesc}>
                  We couldn't upload your recording. Please check your
                  connection and try again.
                </div>
              </div>
            </div>
            <div className={styles.resetButtons}>
              <Button
                label="Try Again"
                className={styles.tryAgainButton}
                onClick={() => {
                  if (savedMediaBlobs.current) {
                    onRecordingComplete(
                      savedMediaBlobs.current?.audioBlob,
                      savedMediaBlobs.current?.videoBlob,
                    );
                  }
                }}
              />
              <RecordAgainButton
                isMobile={isMobile}
                onClick={() => {
                  userMediaRef?.current?.reset();
                  savedMediaBlobs.current = null;
                  setUploading(null);
                  setUploadError(false);
                  setTranscription("");
                }}
              />
            </div>
          </div>
        )}

        <UserMediaRecorder
          className={classNames(
            styles.recorder,
            (editing || uploadError || uploading) && styles.hidden,
          )}
          ref={userMediaRef}
          initialConstraints={initialMediaConstraints}
          onRequestTextInput={onSwitchToText}
          onRequestTranscriptEdit={(text) => {
            setEditing(true);

            if (text) setEditedTranscript(text);
          }}
          onMediaRecorded={onRecordingComplete}
          transcribeError={transcribeError}
          onRetry={transcribeAudio}
        >
          {SubmitRecordAgainContent || ""}
        </UserMediaRecorder>
      </div>
    </>
  );
};
