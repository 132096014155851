import { Chat, createGUID } from "@arena-active/client-lib";
import { trpc } from "@arena-active/trpc-client";

export interface AiChatActivityProps {
  limit?: {
    videoIds: number[];
  };
}

export function AiChatActivity({ limit }: AiChatActivityProps) {
  const { mutateAsync } = trpc.userAiChat.ragChat.useMutation();

  return (
    <Chat
      initialMessages={[
        {
          uid: createGUID(),
          timestamp: Date.now(),
          type: "SYSTEM" as const,
          content:
            'Hello, I\'m here to help you with any questions you might have about course Content. Feel free to ask me anything - maybe something like "How do shadows play a role in cinema?"',
        },
      ]}
      messageHandler={(userInput) =>
        mutateAsync({ query: userInput, limit }).then(
          ({ answer, playlist }) => [
            {
              uid: createGUID(),
              timestamp: Date.now(),
              type: "ASSISTANT" as const,
              content: answer,
              videoReferences: playlist,
            },
          ],
        )
      }
    />
  );
}
